import { useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import { FaPen, FaTrash } from 'react-icons/fa'

const FViewCombo = () => {
    const { comboSelected, setFormView, setViewOpen } = useContext(ContextStates)


    return (
        <div className="w-full rounded mt-12 p-3">
            <h2 className='text-center text-gray-700 text-xl uppercase'>Productos que componen el combo</h2>
            {typeof comboSelected.productos !== 'undefined' && (
                <table className='w-full mx-auto'>
                    <thead>
                        <tr className='p-1 bg-blue-400 text-white text-center'>
                            <th>Código</th>
                            <th>Descripción</th>
                            <th>Cantidad</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            comboSelected.productos.map((item, index) => (
                                <tr key={index} className='border border-gray-300 p-2 text-center'>
                                    <td>{item.codigo}</td>
                                    <td>{item.descripcion}</td>
                                    <td>{item.cantidad}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            )}
                <div className='w-1/3 mx-auto mt-5'>
                    <button
                        type='button'
                        className='w-full p-2 bg-gray-400 text-white uppercase font-semibold rounded'
                        onClick={() => setViewOpen(false)}
                    >
                        Salir
                    </button>
                </div>
        </div>
    )
}

export default FViewCombo