import { useState, useContext, useEffect, Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Link } from 'react-router-dom'
import { FiMenu } from "react-icons/fi"
import { GrClose } from "react-icons/gr"
import ContextStates from '../../context/ContextStates';
import { IoMdHome } from "react-icons/io";
import { MdOutlineAttachMoney } from "react-icons/md";
import { FaChartBar } from "react-icons/fa";
import { BsFillGearFill } from "react-icons/bs";
import { IoExit } from "react-icons/io5";
import { FaUsersCog } from "react-icons/fa";
import { MdBrandingWatermark } from "react-icons/md";
import { FaSitemap } from "react-icons/fa";
import { FaIceCream } from "react-icons/fa";
import { IoIosPricetags } from "react-icons/io";
import { FaUsers } from "react-icons/fa";
import { PiBeerBottleThin } from "react-icons/pi";
import { FaCashRegister } from "react-icons/fa";
import { FaPercent } from "react-icons/fa";
import { FaHouseUser } from "react-icons/fa";
import { TbCalendarDollar } from "react-icons/tb";
import SideLink from './sideLink';

const SideBar = () => {
    const [show, setShow] = useState(false)
    const { roles } = useContext(ContextStates);
    const cancelButtonRef = useRef(null)

    return (
        <>
        <div className='fixed z-40'>
			<button type='button'
				className={`${show ? "hidden" : "block"} z-50 relative top-2 left-2 text-white bg-slate-600 hover:bg-slate-500 flex items-center justify-center w-10 h-10 rounded-full`}
				onClick={() => setShow(true)}
			>
				<FiMenu />
			</button>
			<Transition.Root show={show} as={Fragment}>
				<Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setShow}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed z-10 inset-0 overflow-y-auto">
						<div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 w-0 translate-x-0"
								enterTo="opacity-100 w-full scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-x-0 scale-100"
								leaveTo="opacity-0 translate-x-4 translate-x-0"
							>
								<Dialog.Panel className="bg-black/90 fixed top-0 z-10 inset-0 text-left overflow-hidden overflow-y-auto shadow-xl transform transition-all 2xl:w-1/6 lg:w-1/4 md:w-1/2 w-11/12">
									<button
										className={`${!show ? "hidden" : "block"} relative top-2 left-2 text-white bg-gray-700 hover:bg-gray-600 flex items-center justify-center w-10 h-10 rounded-full`}
										type='button'
										value='Close Sidebar'
										onClick={() => setShow(false)}
									>
										<GrClose />
									</button>
									<div className="px-4 pt-2 sm:p-6">
										<div className="sm:flex sm:items-start">
											<div
												className={`${!show ? "hidden" : "block"}`}
											>
												<div className='overflow-y-auto flex-1'>
													<ul className="relative mt-5 mb-5 pb-5 border-b border-gray-500">
														<SideLink link="/admin/" id={0} label="Inicio"><IoMdHome /></SideLink>
                                                        <SideLink link="/admin/productos" id={1} label="Productos"><FaIceCream /></SideLink>
                                                        <SideLink link="/admin/marcas" id={2} label="Marcas"><MdBrandingWatermark /></SideLink>
                                                        <SideLink link="/admin/rubros" id={3} label="Rubros"><FaSitemap /></SideLink>
                                                        <SideLink link="/admin/ventas" id={4} label="Ventas"><MdOutlineAttachMoney /></SideLink>
                                                        <SideLink link="/admin/compras" id={5} label="Compras"><TbCalendarDollar /></SideLink>
                                                        <SideLink link="/admin/jornadas" id={6} label="Jornadas"><FaCashRegister /></SideLink>
														{roles.includes("1") && (
															<>
																<SideLink link="/admin/precios" id={7} label="Precios"><IoIosPricetags /></SideLink>
																<SideLink link="/admin/promociones" id={8} label="Promociones"><FaPercent /></SideLink>
																<SideLink link="/admin/clientes" id={9} label="Clientes"><FaUsers /></SideLink>
																<SideLink link="/admin/proveedores" id={10} label="Proveedores"><FaHouseUser /></SideLink>
															</>
														)}
                                                        {/*}<SideLink link="/admin/envases" id={11} label="Envases"><PiBeerBottleThin /></SideLink>{*/}
														<SideLink link="/admin/envases" id={11} label="Envases"><PiBeerBottleThin /></SideLink>
                                                        <SideLink link="/admin/reportes" id={12} label="Reportes"><FaChartBar /></SideLink>
                                                        <SideLink link="/admin/adminusuarios" id={13} label="Administrar usuarios"><FaUsersCog /></SideLink>
                                                        <SideLink link="/admin/configuracion" id={14} label="Configuración"><BsFillGearFill /></SideLink>
                                                        <SideLink link="/" id={15} label="Volver a ventas"><IoExit /></SideLink>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</div>
        </>
    )
}

export default SideBar