import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import ContextStates from '../../context/ContextStates'
import { Url } from '../Url'
import { FaPen, FaTrash } from 'react-icons/fa'
import Select from 'react-select';

const FEditCombo = () => {
    const [productosCombo, setProductosCombo] = useState([])
    const [productos, setProductos] = useState([])
    const [producto, setProducto] = useState(0)
    const [codigo, setCodigo] = useState("")
    const [nombre, setNombre] = useState("")
    const [precio, setPrecio] = useState("")
    const [cantidad, setCantidad] = useState(0)
    const [fechaInicio, setFechaInicio] = useState('')
    const [fechaFin, setFechaFin] = useState('')
    const [modificado, setModificado] = useState(false)
    const [modificado1, setModificado1] = useState(false)
    const { comboSelected, modify, setModify, setViewOpen } = useContext(ContextStates)

    useEffect(() => {
        setCodigo(comboSelected.codigo)
        setNombre(comboSelected.nombre)
        setPrecio(comboSelected.precio)
        setFechaInicio(comboSelected.fechainicio)
        setFechaFin(comboSelected.fechafin)
        setProductosCombo(comboSelected.productos)
    }, [comboSelected])

    useEffect(() => {
        let auxGuardar = true;
        if (codigo==='' || nombre==='' || precio==='' || precio===0 || fechaInicio==='' || fechaFin==='' || productosCombo.length===0)
            auxGuardar = false
        if (codigo===comboSelected.codigo && nombre===comboSelected.nombre && precio===comboSelected.precio && fechaInicio===comboSelected.fechainicio && fechaFin===comboSelected.fechafin)
            auxGuardar = false
        if (auxGuardar)
            setModificado1(true)
        else
            setModificado1(false)
    }, [codigo, nombre, precio, precio, fechaInicio, fechaFin, productosCombo])

    useEffect(() => {
        const getProductos = async () => {
            const formData = new FormData()
            try {
                const results = await axios({
                    url: Url + 'api/listadoproductos.php',
                    method: 'POST',
                    data: formData,
                })
                setProductos(results.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        getProductos();
    }, [])

    const addProductoCombo = async () => {
        if (producto !== 0) {
            let rData = [...productosCombo]
            let encontrado = 0;
            rData.forEach(element => {
                if (element.id === producto) {
                    encontrado = 1;
                }
            });
            if (encontrado === 0) {
                let rAux = [...productos]
                rAux.forEach(element => {
                    if (element.id === producto) {
                        element.cantidad = cantidad;
                        rData.push(element)
                    }
                });
                setProductosCombo(rData)
            }
        }
    }

    const delProductoCombo = async (id) => {
        let rData = [];
        productosCombo.forEach(element => {
            if (element.id !== id) {
                rData.push(element)
            }
        });
        setProductosCombo(rData)
    }

    const handleSetCantidad = (e, index) => {
        let rData = [...productosCombo]
        rData.forEach(element => {
            if (element.id === index) {

                element.cantidad = e
                setModificado(true)
                return
            }
        });
        setProductosCombo(rData);
    }

    const handleSelect = (element) => {
        setProducto(element.id)
    }

    const setCombo = async () => {
        if (modificado) {
            const formData = new FormData()
            const auxProductos = JSON.stringify(productosCombo)
            formData.append('id', comboSelected.id);
            formData.append('codigo', codigo);
            formData.append('nombre', nombre);
            formData.append('precio', precio);
            formData.append('fechaInicio', fechaInicio);
            formData.append('fechaFin', fechaFin);
            formData.append('productos', auxProductos);
            try {
                const response = await axios({
                    url: Url + 'api/combosSet.php',
                    method: 'POST',
                    data: formData,
                })
                console.log(response)
                let aux = modify + 1;
                setModify(aux);
                setViewOpen(false)
            } catch (e) {
                console.log(e)
            }
        }

    }

    return (
        <div className="w-full rounded mt-12 p-3">
            <h3 className='text-2xl text-center mb-5 text-gray-600'>Combo {comboSelected.nombre}</h3>
            <div className='w-full flex'>
                <div className="sm:items-start w-full sm:w-1/3 p-2">
                    <label htmlFor="codigo" className="block text-gray-600 uppercase font-bold">Código</label>
                    <input
                        className="py-2 px-3 w-full rounded bg-white border border-gray-300"
                        id="codigo"
                        placeholder="Código"
                        type="text"
                        value={codigo}
                        onChange={(e) => setCodigo(e.target.value)}
                    />
                </div>
                <div className="sm:items-start w-full sm:w-2/3 p-2">
                    <label htmlFor="nombre" className="block text-gray-600 uppercase font-bold">Nombre o Descripción</label>
                    <input
                        className="py-2 px-3 w-full rounded bg-white border border-gray-300"
                        id="nombre"
                        placeholder="Nombre o Descripción"
                        type="text"
                        value={nombre}
                        onChange={(e) => setNombre(e.target.value)}
                    />
                </div>
            </div>
            <div className="sm:items-start w-full sm:w-1/3 p-2">
                <label htmlFor="precio" className="block text-gray-600 uppercase font-bold">Precio de Venta</label>
                <input
                    className="py-2 px-3 w-full rounded bg-white border border-gray-300"
                    id="precio"
                    placeholder="Precio de Venta"
                    type="text"
                    value={precio}
                    onChange={(e) => setPrecio(e.target.value)}
                />
            </div>
            <div className='w-full flex'>
                <div className='w-1/2 p-2'>
                    <label htmlFor="fechaInicio" className="w-full block text-gray-600 uppercase font-bold">Fecha de Inicio</label>
                    <input
                        className="py-2 px-3 w-full rounded border border-gray-300"
                        id="fechaInicio"
                        placeholder="Fecha de Inicio"
                        type="date"
                        value={fechaInicio}
                        onChange={(e) => setFechaInicio(e.target.value)}
                    />
                </div>
                <div className='w-1/2 p-2'>
                    <label htmlFor="fechaFin" className="w-full block text-gray-600 uppercase font-bold">Fecha de Fin</label>
                    <input
                        className="py-2 px-3 w-full rounded border border-gray-300"
                        id="fechaFin"
                        placeholder="Fecha de Fin"
                        type="date"
                        value={fechaFin}
                        onChange={(e) => setFechaFin(e.target.value)}
                    />
                </div>
            </div>
            <h2 className='text-center text-gray-700 text-xl uppercase'>Productos que componen el combo</h2>
            {typeof productosCombo !== 'undefined' && (
                <table className='w-full mx-auto'>
                    <thead>
                        <tr className='p-1 bg-blue-400 text-white text-center'>
                            <th>Código</th>
                            <th>Descripción</th>
                            <th className='w-1/12'>Cantidad</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            productosCombo.map((item, index) => (
                                <tr key={index} className='border border-gray-300 p-2 text-center'>
                                    <td>{item.codigo}</td>
                                    <td>{item.descripcion}</td>
                                    <td className='sm:w-1/12'>
                                        <input type='text' className='w-full px-2 text-right' value={item.cantidad} onChange={(e) => handleSetCantidad(e.target.value, item.id)} />
                                    </td>
                                    <td className='p-1 flex justify-center'>
                                        <button
                                            className='bg-red-500 p-3 rounded-md text-white ml-3'
                                            onClick={() => delProductoCombo(item.id)}
                                        >
                                            <FaTrash />
                                        </button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            )}
            <div className='sm:items-start flex'>
                <div className='w-2/3 p-2'>
                    <label htmlFor="cantidad" className="block mt-5 text-gray-600 uppercase font-bold">Seleccione Productos</label>
                    <Select
                        options={productos}
                        placeholder="Seleccione Producto para agregar a la promoción"
                        onChange={(value) => handleSelect(value)}
                        name='sProducto'
                    />
                    <button
                        type='button'
                        className={`mt-2 text-white p-3 rounded font-bold ${(producto !== 0 && cantidad!==0) ? 'bg-blue-600' : 'bg-blue-200'}`}
                        onClick={() => addProductoCombo()}
                    >Agregar Producto seleccionado a la promoción</button>
                </div>
                <div className='w-1/3 p-2'>
                    <label htmlFor="cantidad" className="block mt-5 text-gray-600 uppercase font-bold">Cantidad</label>
                    <input
                        className="py-2 px-3 w-full rounded bg-white border border-gray-300"
                        id="cantidad"
                        placeholder="Cantidad"
                        type="text"
                        value={cantidad}
                        onChange={(e) => setCantidad(e.target.value)}
                    />
                </div>
            </div>
            <div className='w-full flex mt-5'>
                <button
                    type="button"
                    className={`w-1/3 sm:w-1/4 shadow-md p-3 ml-3 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg ${(modificado || modificado1) ? 'bg-blue-500' : 'bg-blue-200'} `}
                    onClick={() => setCombo()}
                >
                    Guardar datos
                </button>
                <button
                    type="button"
                    className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => setViewOpen(false)}
                >
                    Salir
                </button>
            </div>
        </div>
    )
}

export default FEditCombo