import { useState } from "react";
import AuthState from "./context/auth/authState";
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import ModalView from "./components/ModalView";
import Index from "./pages/Index";
import Producto from "./pages/Producto";
import Categoria from "./pages/Categoria";
import Marca from "./pages/Marca";
import ContextStates from "./context/ContextStates";
import Login from "./pages/Login";
import Reportes from "./pages/admin/reportes";
import Search from "./pages/Search";
import IndexAdmin from "./pages/admin/Index";
import LoginAdmin from "./pages/admin/Login";
import AltaProductos from "./pages/admin/altaProductos";
import Productos from "./pages/admin/productos";
import Marcas from "./pages/admin/marcas";
import Rubros from "./pages/admin/rubros";
import Precios from "./pages/admin/precios";
import Configuracion from "./pages/admin/configuracion";
import GestionArticulos from "./pages/admin/gestionArticulos";
import Ventas from "./pages/admin/ventas";
import Clientes from "./pages/admin/clientes";
import AdminUsuarios from "./pages/admin/adminUsuarios";
import Jornadas from "./pages/jornadas";
import Envases from "./pages/admin/envases";
import JornadasAdmin from "./pages/admin/jornadas";
import Promociones from "./pages/admin/promociones";
import Proveedores from "./pages/admin/proveedores";
import Compras from "./pages/admin/compras";

function App() {
	const [nombre, setNombre] = useState("");
	const [itemSidebar, setItemSidebar] = useState(0);
	const [productos, setProductos] = useState([]);
	const [combos, setCombos] = useState([]);
	const [roles, setRoles] = useState([]);
	const [productoSelected, setProductoSelected] = useState({});
	const [productoSearch, setProductoSearch] = useState('');
	const [filProductos, setFilProductos] = useState([]);
	const [marcas, setMarcas] = useState([]);
	const [marcaSelected, setMarcaSelected] = useState({});
	const [marcaSearch, setMarcaSearch] = useState('');
	const [rubros, setRubros] = useState([]);
	const [rubroSelected, setRubroSelected] = useState({});
	const [rubroSearch, setRubroSearch] = useState('');
	const [venta, setVenta] = useState([]);
	const [ventaSelected, setVentaSelected] = useState({});
	const [viewOpen, setViewOpen] = useState(false);
	const [formView, setFormView] = useState(0);
	const [idUsuario, setIdUsuario] = useState(0);
	const [mail, setMail] = useState('');
	const [medioPago, setMedioPago] = useState(0);
	const [file, setFile] = useState([]);
	const [imagenSubida, setImagenSubida] = useState(0);
	const [modify, setModify] = useState(0);
	const [viewAdminOpen, setViewAdminOpen] = useState(0);
	const [formAdminView, setFormAdminView] = useState(false);
	const [valMarca, setValMarca] = useState(0);
	const [valRubro, setValRubro] = useState(0);
	const [viewSearch, setViewSearch] = useState(false);
	const [formPrev, setFormPrev] = useState(0);
	const [usuarios, setUsuarios] = useState([])
	const [buscarUsuario, setBuscarUsuario] = useState("")
	const [usuarioSelected, setUsuarioSelected] = useState({})
	const [clientes, setClientes] = useState([])
	const [buscarCliente, setBuscarCliente] = useState("")
	const [clienteSelected, setClienteSelected] = useState({})
	const [puntoVenta, setPuntoVenta] = useState(0)
	const [puntos, setPuntos] = useState([])
	const [puntoSelected, setPuntoSelected] = useState({})
	const [idJornada, setIdJornada] = useState(0)
	const [estadoJornada, setEstadoJornada] = useState(0)
	const [precio, setPrecio] = useState(false)
	const [prestamoSelected, setPrestamoSelected] = useState({})
	const [promoSelected, setPromoSelected] = useState({})
	const [proveedores, setProveedores] = useState([])
	const [proveedorSelected, setProveedorSelected] = useState({})
	const [buscarProveedor, setBuscarProveedor] = useState("")
	const [detalleCompra, setDetalleCompra] = useState([])
	const [proveedor, setProveedor] = useState(0)
	const [rubro, setRubro] = useState(0)
	const [compraSelected, setCompraSelected] = useState({})
	const [lineaCompra, setLineaCompra] = useState({})
	const [comboSelected, setComboSelected] = useState({})

	return (

		<AuthState>
			<ContextStates.Provider
				value={{
					nombre, setNombre,
					itemSidebar, setItemSidebar,
					productos, setProductos,
					combos, setCombos,
					roles, setRoles,
					productoSelected, setProductoSelected,
					productoSearch, setProductoSearch,
					filProductos, setFilProductos,
					marcas, setMarcas,
					marcaSelected, setMarcaSelected,
					marcaSearch, setMarcaSearch,
					rubros, setRubros,
					rubroSelected, setRubroSelected,
					rubroSearch, setRubroSearch,
					venta, setVenta,
					ventaSelected, setVentaSelected,
					viewOpen, setViewOpen,
					formView, setFormView,
					idUsuario, setIdUsuario,
					mail, setMail,
					medioPago, setMedioPago,
					file, setFile,
					imagenSubida, setImagenSubida,
					modify, setModify,
					viewAdminOpen, setViewAdminOpen,
					formAdminView, setFormAdminView,
					valMarca, setValMarca,
					valRubro, setValRubro,
					viewSearch, setViewSearch,
					formPrev, setFormPrev,
					usuarios, setUsuarios,
					buscarUsuario, setBuscarUsuario,
					usuarioSelected, setUsuarioSelected,
					clientes, setClientes,
					buscarCliente, setBuscarCliente,
					clienteSelected, setClienteSelected,
					puntoVenta, setPuntoVenta,
					puntos, setPuntos,
					puntoSelected, setPuntoSelected,
					idJornada, setIdJornada,
					estadoJornada, setEstadoJornada,
					precio, setPrecio,
					prestamoSelected, setPrestamoSelected,
					promoSelected, setPromoSelected,
					proveedores, setProveedores,
					proveedorSelected, setProveedorSelected,
					buscarProveedor, setBuscarProveedor,
					detalleCompra, setDetalleCompra,
					proveedor, setProveedor,
					rubro, setRubro,
					compraSelected, setCompraSelected,
					lineaCompra, setLineaCompra,
					comboSelected, setComboSelected
				}}
			>
				<BrowserRouter>
					<Routes>
						<Route path='/producto/:id' element={<Producto />} />
						<Route path='/categoria/:id' element={<Categoria />} />
						<Route path='/marca/:id' element={<Marca />} />
						<Route path='/' element={<Index />} />
						<Route path='/login' element={<Login />} />
						<Route path='/search' element={<Search />} />
						<Route path='/admin/' element={<IndexAdmin />} />
						<Route path='/admin/login' element={<LoginAdmin />} />
						<Route path='/admin/altaproductos' element={<AltaProductos />} />
						<Route path='/admin/reportes' element={<Reportes />} />
						<Route path='/admin/productos' element={<Productos />} />
						<Route path='/admin/marcas' element={<Marcas />} />
						<Route path='/admin/rubros' element={<Rubros />} />
						<Route path='/admin/precios' element={<Precios />} />
						<Route path='/admin/configuracion' element={<Configuracion />} />
						<Route path='/admin/gestionarticulos' element={<GestionArticulos />} />
						<Route path='/admin/ventas' element={<Ventas />} />
						<Route path='/admin/clientes' element={<Clientes />} />
						<Route path='/admin/envases' element={<Envases />} />
						<Route path='/jornadas' element={<Jornadas />} />
						<Route path='/admin/adminusuarios' element={<AdminUsuarios />} />
						<Route path='/admin/jornadas' element={<JornadasAdmin />} />
						<Route path='/admin/promociones' element={<Promociones />} />
						<Route path='/admin/proveedores' element={<Proveedores />} />
						<Route path='/admin/compras' element={<Compras />} />
					</Routes>
				</BrowserRouter>
				<ModalView />
			</ContextStates.Provider>
		</AuthState>

	);
}

export default App;

