import React, { useContext } from 'react'
import authContext from '../context/auth/authContext'

const Alert = () => {
    const AuthContext = useContext(authContext);
    const { mensaje } = AuthContext;
  
    return (
        <div className='w-11/12 absolute mx-auto mr-10'>
            <div className='bg-red-500/90 uppercase text-bold text-center text-white p-3 w-11/12 mx-auto rounded-xl z-50'>
                {mensaje}
            </div>
        </div>
    );
}
 
export default Alert;