import { useState, useEffect, useContext } from 'react'
import ContextStates from '../../context/ContextStates'

const ViewVenta = () => {
    const [total, setTotal] = useState(0)
    const { ventaSelected, setFormView, setViewOpen } = useContext(ContextStates)
    useEffect(() => {
        const calcTotal = () => {
            let t = 0;
            if (typeof ventaSelected.productos !== 'undefined') {
                ventaSelected.productos.forEach(element => {
                    t = t + parseInt(element.monto)
                });
                setTotal(t)
            }
        }
        calcTotal()
    }, [ventaSelected])

    return (
        <div className="w-full py-5 px-2 md:px-20 rounded-2xl">
            <h2 className='text-center text-gray-700 text-xl'>Venta Nº {ventaSelected.numerocomprobante}</h2>
            {ventaSelected.productos && (
                <table className='w-full md:w-3/4 mx-auto'>
                    <thead>
                        <tr className='p-1 bg-blue-400 text-white text-center'>
                            <th>Código</th>
                            <th>Descripción</th>
                            <th>Descripción Ext.</th>
                            <th>Cantidad</th>
                            <th>Monto</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            ventaSelected.productos.map((item,index) => (
                                <tr key={index} className='border border-gray-300 p-2 text-center'>
                                    <td>{item.codigo}</td>
                                    <td>{item.descripcion}</td>
                                    <td>{item.descripcionext}</td>
                                    <td>{item.cantidad}</td>
                                    <td>$ {parseFloat(item.monto).toLocaleString("es-AR", { maximumFractionDigits: 2 })}</td>
                                </tr>
                            ))
                        }
                        <tr className='border border-gray-300 p-2 text-center'>
                            <th colSpan={4}>Total</th>
                            <th>$ {parseFloat(total).toLocaleString("es-AR", { maximumFractionDigits: 2 })}</th>
                        </tr>
                    </tbody>
                </table>
            )}
            {ventaSelected.descuentos && (
                <div className='mt-5'>
                    <h3 className='text-center text-xl uppercase'>Descuentos</h3>
                    <table className='w-full md:w-3/4 mx-auto'>
                        <thead>
                            <tr className='p-1 bg-blue-400 text-white text-center'>
                                <th>Descripción</th>
                                <th>Monto</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                ventaSelected.descuentos.map(item => (
                                    <tr className='border border-gray-300 p-2 text-center'>
                                        <td>{item.descripcion}</td>
                                        <td>$ {parseFloat(item.monto).toLocaleString("es-AR", { maximumFractionDigits: 2 })}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            )}
            <div className='mt-5 w-3/4 mx-auto bg-green-300 rounded-xl p-3'>
                <p className='text-gray-600 text-2xl text-center font-semibold'>Medio de Pago: {ventaSelected.medio}</p>
            </div>
            <div className="w-full rounded-lg px-3 flex justify-center mt-10">
                <button
                    type="button"
                    className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => setViewOpen(false)}
                >
                    Salir
                </button>
            </div>
        </div>
    )
}

export default ViewVenta