export const REGISTRO_EXITOSO = 'REGISTRO_EXITOSO';
export const REGISTRO_ERROR = 'REGISTRO_ERROR';

export const LOGIN_EXITOSO = 'LOGIN_EXITOSO';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const LIMPIAR_ALERTA = 'LIMPIAR_ALERTA';
export const RESTABLECER_REGISTRO = 'RESTABLECER_REGISTRO';


export const USUARIO_AUTENTICADO = 'USUARIO_AUTENTICADO';

export const CERRAR_SESION = 'CERRAR_SESION';