import { useState, useEffect, useContext } from 'react'
import ContextStates from '../../context/ContextStates'
import axios from 'axios'
import { Url } from '../Url'


const ListadoVentas = () => {
    const [ventas, setVentas] = useState([])
    const [puntos, setPuntos] = useState([])
    const [punto, setPunto] = useState(0)
    const [total, setTotal] = useState(0)
    const [fechaInicio, setFechaInicio] = useState('')
    const [fechaFin, setFechaFin] = useState('')
    const [medios, setMedios] = useState([])
    const [medio, setMedio] = useState(0)
    const { setVentaSelected, setFormView, setViewOpen, modify } = useContext(ContextStates)

    useEffect(() => {
        const getMedios = async () => {
            const formData = new FormData()
            formData.append('mediocero', 1);
            try {
                const results = await axios({
                    url: Url + 'api/listmediospago.php',
                    method: 'POST',
                    data: formData,
                })
                setMedios(results.data.results);
            } catch (e) {
                console.log(e)
            }
        }
        getMedios()
    }, [])

    useEffect(() => {
        const getPuntos = async () => {
            const formData = new FormData()
            formData.append('puntocero', 1);
            try {
                const results = await axios({
                    url: Url + 'api/listPuntos.php',
                    method: 'POST',
                    data: formData,
                })
                setPuntos(results.data.results);
            } catch (e) {
                console.log(e)
            }
        }
        getPuntos()
    }, [])

    useEffect(() => {
        const calcTotal = () => {
            let t = 0;
            if (typeof ventas !== 'undefined') {
                ventas.forEach(element => {
                    t = t + parseInt(element.total)
                });
                setTotal(t)
            }
        }
        calcTotal()
    }, [ventas])

    useEffect(() => {
        const consultaVentas = async () => {
            const formData = new FormData()
            formData.append('fechainicio', fechaInicio)
            formData.append('fechafin', fechaFin)
            formData.append('medio', medio)
            formData.append('punto', punto)
            try {
                const response = await axios({
                    url: Url + 'api/listadoVentas.php',
                    method: 'POST',
                    data: formData,
                })
                console.log(response)
                setVentas(response.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        consultaVentas()
    }, [fechaInicio, fechaFin, medio, modify, punto])

    const handleView = (item) => {
        setVentaSelected(item)
        setFormView(7)
        setViewOpen(true)
    }

    const handleEdit = (item) => {
        setVentaSelected(item)
        setFormView(14)
        setViewOpen(true)
    }


    return (
        <div className="w-full py-5 px-2">
            <h2 className='mt-10 mb-5 text-gray-600 text-3xl text-center'>Ventas</h2>
            <div className="w-full md:w-11/12 mx-auto bg-slate-200 mt-5 mb-5 rounded-xl">
                <div className='w-full flex'>
                    <div className='w-1/2 p-3'>
                        <label htmlFor='fechainicio'>Fecha de Inicio</label>
                        <input type='date' className='w-full p-3 rounded-lg' id='fechainicio' placeholder='Fecha de Inicio' value={fechaInicio} onChange={(e) => setFechaInicio(e.target.value)} />
                    </div>
                    <div className='w-1/2 p-3 ml-5 rounded-xl'>
                        <label htmlFor='fechafin'>Fecha de Fin</label>
                        <input type='date' className='w-full p-3 rounded-lg' id='fechafin' placeholder='Fecha de Fin' value={fechaFin} onChange={(e) => setFechaFin(e.target.value)} />
                    </div>
                </div>
                <div className='w-full p-5'>
                    <select
                        className='py-2 px-3 w-full rounded bg-slate-100 mr-2'
                        id='sMedio'
                        value={medio}
                        onChange={(e) => setMedio(e.target.value)}
                    >
                        {medios && (
                            medios.map((item, index) => (
                                <option key={index} value={item.id}>{item.descripcion}</option>))
                        )}
                    </select>
                </div>
                {puntos && puntos.length > 2 && (
                    <div className='w-full p-5'>
                        <select
                            className='py-2 px-3 w-full rounded bg-slate-100 mr-2'
                            id='sPunto'
                            value={punto}
                            onChange={(e) => setPunto(e.target.value)}
                        >
                            {puntos.map((item, index) => (
                                <option key={index} value={item.id}>{item.nombre}</option>))
                            }
                        </select>
                    </div>
                )}
            </div>
            {typeof ventas !== 'undefined' && (
                <table className='w-full mx-auto'>
                    <thead>
                        <tr className='p-1 bg-blue-400 text-white text-center'>
                            <th>Nº</th>
                            <th>Fecha</th>
                            <th>Hora</th>
                            <th>Total</th>
                            <th>Márgen ganancia</th>
                            <th>Porcentaje ganancia</th>
                            <th>Saldo</th>
                            <th>Medio de Pago</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            ventas.map((item, index) => (
                                <tr key={index} className={`hover:cursor-pointer hover:bg-slate-200 border-b border-gray-200 text-center ${item.saldo && item.saldo > 0 && item.saldo === item.total ? 'bg-red-300' : item.saldo > 0 && item.saldo < item.total ? 'bg-orange-300' : index % 2 === 0 ? 'bg-white ' : 'bg-slate-100'} )`}>
                                    <td>{item.numerocomprobante}</td>
                                    <td>{item.fecha}</td>
                                    <td>{item.hora}</td>
                                    <td>$ {item.total.toLocaleString("es-AR", { maximumFractionDigits: 2 })}</td>
                                    <td>$ {item.margen.toLocaleString("es-AR", { maximumFractionDigits: 2 })}</td>
                                    <td>{item.porcentaje} %</td>
                                    <td>{item.saldo && item.saldo > 0 && '$ ' + item.saldo}</td>
                                    <td>{item.medio}</td>
                                    <td className='text-center'>
                                        <button className='bg-blue-600 hover:bg-blue-400 rounded-lg py-1 px-3 text-white' onClick={() => handleView(item)}>Ver</button>
                                        <button className='bg-red-600 hover:bg-red-400 rounded-lg py-1 px-3 ml-3 text-white' onClick={() => handleEdit(item)}>Editar</button>
                                    </td>
                                </tr>
                            ))
                        }
                        <tr className='border border-gray-300 p-2 text-center font-bold bg-slate-50'>
                            <td>Total</td>
                            <td></td>
                            <td></td>
                            <td>${total.toLocaleString("es-AR", { maximumFractionDigits: 2 })}</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            )}

        </div>
    )
}

export default ListadoVentas