import { createSlice } from "@reduxjs/toolkit";


export const orderSlice = createSlice({
    name: 'orders',
    initialState: [],
    reducers: {
        addOrder: (state, action) => {
            let rData = [...state];
            let encontrado = 0;
            rData.forEach(element => {
                if (element.id===action.payload.id)
                {
                    element.cantidad= parseInt(element.cantidad)+ parseInt(action.payload.cantidad);
                    encontrado=1;
                }
            });
            if (encontrado===1)
            {
                state=[...rData];
            }
            else
            {
                state.push(action.payload)
            }
            localStorage.setItem('localOrder',JSON.stringify(state))
        },
        quitProduct: (state, action) => {
            
            const productFound = state.find(product => product.id === action.payload.id)
            if (productFound) {
                state.splice(state.indexOf(productFound),1)
            }
            localStorage.setItem('localOrder',JSON.stringify(state))
        },
        rechargeOrder: (state, action) => {
            //state=[];
            console.log("intenta cargar ",state.length," contenido ",state)
            if (state.length===0)
            {
                action.payload.forEach(element => {
                    state.push(element)
                });    
            }
        },
        finishOrder: (state, action) => {
            state=[];
            localStorage.removeItem('localOrder');
            console.log("limpia la orden ",state.length," contenido ",state)
        },

    }
})


export const {addOrder,quitProduct,rechargeOrder,finishOrder} = orderSlice.actions
export default orderSlice.reducer