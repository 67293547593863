import { useState, useContext } from 'react';
import Layout from '../../components/admin/Layout';
import ContextStates from '../../context/ContextStates';
import ListadoPromociones from '../../components/admin/listadoPromociones';
import ListadoCombos from '../../components/admin/listadoCombos';
import { FaPlus } from "react-icons/fa";

const Promociones = () => {
    const [tab, setTab] = useState(0)
    const { setFormView, setViewOpen } = useContext(ContextStates)

    const handleAdd = (form) => {
        setFormView(form)
        setViewOpen(true)
    }

    return (
        <Layout
            pagina={"Promociones y combos"}
        >
            <div className='w-full p-5 min-h-full'>
                <h1 className="mt-10 mb-5 text-gray-600 text-3xl text-center">Promociones y Combos</h1>
                <div className='w-full h-screen mt-2 bg-white'>
                    <div className='flex w-full px-8 mb-10'>
                        <button
                            className={`p-2 m-2 rounded-xl ${tab === 1 ? 'border border-indigo-700 text-indigo-700' : 'bg-indigo-700 text-white'}`}
                            onClick={() => setTab(0)}
                        >
                            Promociones
                        </button>
                        <button
                            className={`p-2 m-2 rounded-xl ${tab === 0 ? 'border border-indigo-700 text-indigo-700' : 'bg-indigo-700 text-white'}`}
                            onClick={() => setTab(1)}
                        >
                            Combos
                        </button>
                    </div>
                    {tab === 0 ? (
                        <>
                            <div className='w-full h-16 py-2 px-10 bg-slate-50 flex'>
                                <button
                                    className='bg-indigo-600 hover:bg-indigo-400 p-3 rounded-md text-white uppercase flex'
                                    onClick={() => handleAdd(75)}
                                >
                                    <FaPlus className='text-2xl mr-2' />
                                    Agregar Promociones
                                </button>
                            </div>
                            <div className='w-full h-full p-10 bg-white'>
                                <ListadoPromociones />
                            </div>
                        </>

                    ) : (
                        <>
                            <div className='w-full h-16 py-2 px-10 bg-slate-50 flex'>
                                <button
                                    className='bg-indigo-600 hover:bg-indigo-400 p-3 rounded-md text-white uppercase flex'
                                    onClick={() => handleAdd(76)}
                                >
                                    <FaPlus className='text-2xl mr-2' />
                                    Agregar Combos
                                </button>
                            </div>
                            <div className='w-full h-full p-10 bg-white'>
                                <ListadoCombos />
                            </div>
                        </>

                    )}
                </div>
            </div>
        </Layout>
    )
}

export default Promociones