import { useState, useEffect, useContext, useRef } from 'react'
import ContextStates from '../../context/ContextStates';
import { useFormik } from "formik"
import * as Yup from "yup"
import { FaPen, FaTrash } from 'react-icons/fa'
import axios from 'axios';
import { Url } from '../Url';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import FooterVenta from '../footerVenta';


const FormVentas = () => {
    const [cantidad, setCantidad] = useState(1)
    const [producto, setProducto] = useState({})
    const [combo, setCombo] = useState({})
    const [descripcion, setDescripcion] = useState('')
    const [precioUnitario, setPrecioUnitario] = useState('')
    const { productos, setProductos, combos, setCombos, venta, setVenta, setProductoSelected, setViewOpen, modify, setFormView, precio } = useContext(ContextStates)

    const inputRef = useRef(null);

    useEffect(() => {
        const getProductos = async () => {
            const formData = new FormData()
            formData.append('id', 1);
            try {
                const results = await axios({
                    url: Url + 'api/listadoproductos.php',
                    method: 'POST',
                    data: formData,
                })
                //console.log(results.data.results)
                setProductos(results.data.results);
            } catch (e) {
                console.log(e)
            }
        }
        getProductos()
    }, [modify])

    useEffect(() => {
        const getCombos = async () => {
            const formData = new FormData()
            formData.append('id', 1);
            try {
                const results = await axios({
                    url: Url + 'api/listCombos.php',
                    method: 'POST',
                    data: formData,
                })
                console.log(results.data.results)
                setCombos(results.data.results);
            } catch (e) {
                console.log(e)
            }
        }
        getCombos()
    }, [modify])


    useEffect(() => {
        const addProducto = async () => {
            if (typeof producto !== 'undefined') {
                if (producto.codigo) {
                    const auxVenta = JSON.stringify(venta)
                    const formData = new FormData()
                    formData.append('venta', auxVenta);
                    formData.append('producto', JSON.stringify(producto));
                    //formData.append('cantidad', producto.cantidad);
                    try {
                        const response = await axios({
                            url: Url + 'api/addProducto.php',
                            'Content-Type': 'application/json',
                            method: 'POST',
                            data: formData,
                            dataType: 'JSON',
                        })
                        //console.log(response)
                        setVenta(response.data.results);
                        setDescripcion('')
                        //encontrado = 1;
                        setProducto({})
                        setCantidad(1)
                        setPrecioUnitario('')
                    } catch (e) {
                        console.log(e)
                    }
                }
            }
        }
        addProducto()
    }, [producto])

    useEffect(() => {
        const addCombo = async () => {
            if (typeof combo !== 'undefined') {
                if (combo.codigo) {
                    const auxVenta = JSON.stringify(venta)
                    const formData = new FormData()
                    formData.append('venta', auxVenta);
                    formData.append('combo', JSON.stringify(combo));
                    //formData.append('cantidad', producto.cantidad);
                    try {
                        const response = await axios({
                            url: Url + 'api/addCombo.php',
                            'Content-Type': 'application/json',
                            method: 'POST',
                            data: formData,
                            dataType: 'JSON',
                        })
                        console.log(response)
                        setVenta(response.data.results);
                        setDescripcion('')
                        //encontrado = 1;
                        setCombo({})
                        setCantidad(1)
                        setPrecioUnitario('')
                    } catch (e) {
                        console.log(e)
                    }
                }
            }
        }
        addCombo()
    }, [combo])

    const formik = useFormik({
        initialValues: {
            codigo: ''
        },
        validationSchema: Yup.object({
            codigo: Yup.string()
        }),
        onSubmit: async (valores, { resetForm }) => {
            //console.log(valores.codigo)
            const formData = new FormData()
            formData.append('codigo', valores.codigo);
            formData.append('cantidad', cantidad);

            let aux  = await productos.find(element => element.codigo === valores.codigo);
            if (typeof aux!=='undefined') {
                aux.cantidad = cantidad;
                setProducto(aux)
                setDescripcion('')
            }
            else {
                aux = await combos.find(element => element.codigo === valores.codigo);
                if (typeof aux!=='undefined') {
                    aux.cantidad = cantidad;
                    setCombo(aux)
                    setDescripcion('')
                }    
            }
            //aux.precio = precioUnitario;
            //console.log("producto ",aux)

            /*try {
                const results = await axios({
                    url: Url + 'api/agregarproducto.php',
                    method: 'POST',
                    data: formData,
                })
                setProducto(results.data.results);
                setDescripcion('')
            } catch (e) {
                console.log(e)
            }*/

            resetForm({ values: '' });
        }
    });

    useEffect(() => {
        const checkDatos = () => {
            productos.forEach(element => {
                if (element.codigo === formik.values.codigo) {
                    if (!precio) {
                        if (element.tipopromo==='1' || element.tipopromo==='2') {
                            setPrecioUnitario(element.preciopromocion)    
                        }
                        else {
                            setPrecioUnitario(element.precio)
                        }
                    }
                    else {
                        setPrecioUnitario(element.preciopormayor)
                    }

                }
            });
        }
        checkDatos()
    }, [formik.values.codigo])

    useEffect(() => {
        const handleSetPrecio = () => {
            if (formik.values.codigo!='') {
                productos.forEach(element => {
                    if (element.codigo===formik.values.codigo) {
                        if (element.idunidad!=='1') {
                            /*console.log("precio unitario ",parseFloat(precioUnitario))
                            console.log("precio elemento ",parseFloat(element.precio))*/
                            let aux=0;
                            if (!precio) {
                                if (element.tipopromo==='1' || element.tipopromo==='2') {
                                    aux = parseFloat(precioUnitario) / parseFloat(element.preciopromocion);
                                }
                                else {
                                    aux = parseFloat(precioUnitario) / parseFloat(element.precio);
                                }
                            }
                            else {
                                aux = parseFloat(precioUnitario) / parseFloat(element.preciopormayor);
                            }
                            aux = aux.toFixed(5)
                            setCantidad(aux)
                        }
                    }
                });
            }
        }
        handleSetPrecio()
    },[precioUnitario])

    const handleOnSelect = (item) => {
        formik.setFieldValue("codigo", item.codigo)
        inputRef.current.focus()
        item = [];
    }

    const submitCantidad = (event) => {
        event.preventDefault();
        if (cantidad !== '')
            inputRef.current.focus()
    }

    const handleAdd = () => {
        formik.handleSubmit();
        inputRef.current.focus()
    }

    const handleDelete = (item) => {
        let rData = []
        venta.forEach(element => {
            if (element.id !== item.id) {
                rData.push(element)
            }
        });
        setVenta(rData)
    }

    const handleOnSearch = (string) => {
        setDescripcion(string)
    }

    const handleSet = (item) => {
        setProductoSelected(item)
        setFormView(1)
        setViewOpen(true)
    }

    const formatResult = (item) => {
        return (
            <span style={{ display: 'block', textAlign: 'left' }}>{item.descripcion}</span>
        )
    }

    const calcTotal = (item) => {
        let precio = 0;
        if (item.cantidad>=item.cantidadminima) {
            let cantPromos = parseFloat(item.cantidad)/parseFloat(item.cantidadminima);
            let cantPagos = item.cantidad-(parseFloat(item.cantidaddescuento)*cantPromos);
            precio = cantPagos * parseFloat(item.precio);
        } else {
            precio = parseFloat(item.cantidad) * parseFloat(item.precio);
        }
        return (precio);
    }

    return (
        <div className='flex flex-col h-screen justify-between'>
            <div className="transition-all w-full mt-3 flex">
                <div className={`w-10/12 mx-auto mt-1 p-3 rounded-lg flex ${!precio ? 'bg-indigo-200':'bg-orange-200'} `}>
                    <form
                        className="w-1/4"
                        onSubmit={formik.handleSubmit}
                    >
                        <div className="sm:items-start px-5">
                            <label htmlFor="codigo" className="block text-gray-600 uppercase font-bold">Código</label>
                            <input
                                type="text"
                                className="py-2 px-3 w-full rounded"
                                id="codigo"
                                ref={inputRef}
                                placeholder="Código de producto"
                                value={formik.values.codigo}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoFocus
                            />
                        </div>
                    </form>
                    <div className="w-1/2 sm:items-start px-5">
                        <label htmlFor="descripcion" className="block text-gray-600 uppercase font-bold">Descripción</label>

                        <ReactSearchAutocomplete
                            items={productos}
                            onSearch={handleOnSearch}
                            onSelect={handleOnSelect}
                            formatResult={formatResult}
                            showClear={true}
                            inputSearchString={descripcion}
                        />
                    </div>
                    <div className="w-1/10 sm:items-start px-5">
                        <label htmlFor="punitario" className="block text-gray-600 uppercase font-bold">Precio Unitario</label>
                        <input
                            type="text"
                            className="py-2 px-3 w-full rounded"
                            id="punitario"
                            value={precioUnitario}
                            onChange={(e)=>setPrecioUnitario(e.target.value)}
                        />
                    </div>
                    <div className="w-1/10 sm:items-start px-5">
                        <form onSubmit={submitCantidad}>
                            <label htmlFor="cantidad" className="block text-gray-600 uppercase font-bold">Cantidad</label>
                            <input
                                type="number"
                                className="py-2 px-3 w-full rounded"
                                id="cantidad"
                                placeholder="Cantidad"
                                value={cantidad}
                                onChange={(e) => setCantidad(e.target.value)}
                            />
                        </form>
                    </div>
                    <div className="w-1/10 sm:items-start px-5 pt-4">
                        <input
                            type="button"
                            className="w-full shadow-md p-3 bg-blue-600 text-gray-100 hover:bg-blue-500 hover:cursor-pointer transition-all uppercase font-semibold rounded mt-1"
                            value="Agregar"
                            onClick={() => handleAdd()}
                        />
                    </div>
                </div>
            </div>
            <div className="w-10/12 mx-auto mt-3 mb-20">
                {typeof venta!=='undefined' && venta.length > 0 && (
                    <table className='table-auto w-full border border-collapse border-gray-300'>
                        <thead>
                            <tr className='border border-collapse border-gray-300 bg-blue-100'>
                                <th className='border border-collapse border-gray-300'>Cantidad</th>
                                <th className='border border-collapse border-gray-300'>Código</th>
                                <th className='border border-collapse border-gray-300'>Descripción</th>
                                <th className='border border-collapse border-gray-300'>Precio</th>
                                <th className='border border-collapse border-gray-300'>Subtotal</th>
                                <th className='border border-collapse border-gray-300'>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {typeof venta!=='undefined' && venta.map((item, index) => (
                                <tr
                                    key={index}
                                    className={`${index % 2 === 0 ? 'bg-white hover:bg-slate-200' : 'bg-slate-100 hover:bg-slate-200'} ' hover:cursor-pointer border-b border-gray-200 ')`}
                                >
                                    <td className='border border-collapse border-gray-300 px-3 text-center'>{item.cantidad}</td>
                                    <td className='border border-collapse border-gray-300 px-3 text-center'>{item.codigo}</td>
                                    <td className='border border-collapse border-gray-300 px-3'>{item.descripcion}</td>
                                    <td className={`border border-collapse border-gray-300 px-3 text-right ${(item.tipopromo==='1' || item.tipopromo==='2') && 'bg-green-200'}`}>{precio ? Math.round(item.preciopormayor) : (item.tipopromo==='1' || item.tipopromo==='2') ? Math.round(item.preciopromocion) : Math.round(item.precio)}</td>
                                    <td className={`border border-collapse border-gray-300 px-3 text-right ${item.tipopromo==='3' && item.cantidad>=item.cantidadminima && 'bg-green-200'}`}>{precio ? (parseFloat(item.preciopormayor) * parseFloat(item.cantidad)).toFixed(0) : (item.tipopromo==='1' || item.tipopromo==='2') ? (parseFloat(item.preciopromocion) * parseFloat(item.cantidad)).toFixed(0) : item.tipopromo==='3' ? calcTotal(item) : (parseFloat(item.precio) * parseFloat(item.cantidad)).toFixed(0)}</td>
                                    <td className='border border-collapse border-gray-300 px-3 text-center'>
                                        <button
                                            className='bg-gray-400 p-3 rounded-md text-white ml-3'
                                            onClick={() => handleSet(item)}
                                        >
                                            <FaPen />
                                        </button>
                                        <button
                                            className='bg-red-400 p-3 rounded-md text-white ml-3'
                                            onClick={() => handleDelete(item)}
                                        >
                                            <FaTrash />
                                        </button>
                                    </td>
                                </tr>

                            ))}
                        </tbody>
                    </table>
                )}
            </div>
            <FooterVenta />
        </div>
    )
}

export default FormVentas