import { createSlice } from "@reduxjs/toolkit";



export const productSlice = createSlice({
    name: 'products',
    initialState: [],
    reducers: {
        addProduct: (state, action) => {
            
            if (state.length===0)
            {
                action.payload.forEach(element => {
                    state.push(element)
                });    
            }
        }
    }
})

export const {addProduct} = productSlice.actions
export default productSlice.reducer