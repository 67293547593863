export const areaAnimateLR={
    offscreen:{x:-200, opacity:0, hidden: true},
    onscreen:{
        x:0, 
        opacity:1,
        hidden: false,
        transition : {
            type: "spring",
            bounce: 0.2,
            duration: 2
        }
    }
}
export const areaAnimateRL={
    offscreen:{x:200, opacity:0, hidden: true},
    onscreen:{
        x:0, 
        opacity:1,
        hidden: false,
        transition : {
            type: "spring",
            bounce: 0.2,
            duration: 2
        }
    }
}

export const areaAnimateDU={
    offscreen:{y:400, opacity:0},
    onscreen:{
        y:0, 
        opacity:1,
        transition : {
            type: "spring",
            bounce: 0.2,
            duration: 3
        }
    }
}

export const areaAnimateS={
    offscreen:{x: 0, opacity:0},
    onscreen:{
        x:0, 
        opacity:1,
        transition : {
            type: "spring",
            bounce: 0.2,
            duration: 4
        }
    }
}