import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import ContextStates from '../../context/ContextStates'
import { Url } from '../Url'
import { FaTrash } from "react-icons/fa";
import Select from 'react-select';

const FormCombos = () => {
    const [productosCombo, setProductosCombo] = useState([])
    const [productos, setProductos] = useState([])
    const [producto, setProducto] = useState(0)
    const [codigo, setCodigo] = useState("")
    const [nombre, setNombre] = useState("")
    const [precio, setPrecio] = useState("")
    const [fechaInicio, setFechaInicio] = useState('')
    const [fechaFin, setFechaFin] = useState('')
    const [cantidad, setCantidad] = useState(0)
    const [guardar, setGuardar] = useState(false)

    const { setViewOpen, modify, setModify } = useContext(ContextStates);

    useEffect(() => {
        const getProductos = async () => {
            const formData = new FormData()
            try {
                const results = await axios({
                    url: Url + 'api/listadoproductos.php',
                    method: 'POST',
                    data: formData,
                })
                setProductos(results.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        getProductos();
    }, [])

    useEffect(() => {
        let auxGuardar = true;
        if (codigo==='' || nombre==='' || precio==='' || precio===0 || fechaInicio==='' || fechaFin==='' || productosCombo.length===0)
            auxGuardar = false
        if (auxGuardar)
            setGuardar(true)
    }, [codigo, nombre, precio, precio, fechaInicio, fechaFin, productosCombo])

    const addProductoCombo = async () => {
        if (producto !== 0) {
            let rData = [...productosCombo]
            let encontrado = 0;
            rData.forEach(element => {
                if (element.id === producto) {
                    encontrado = 1;
                }
            });
            if (encontrado === 0) {
                let rAux = [...productos]
                rAux.forEach(element => {
                    if (element.id === producto) {
                        element.cantidad = cantidad;
                        rData.push(element)
                    }
                });
                setProductosCombo(rData)
            }
        }
    }

    const delProductoCombo = async (id) => {
        let rData = [];
        productosCombo.forEach(element => {
            if (element.id !== id) {
                rData.push(element)
            }
        });
        setProductosCombo(rData)
    }


    const addCombo = async () => {
        if (guardar) {
            const formData = new FormData()
            const auxProductos = JSON.stringify(productosCombo)

            formData.append('codigo', codigo);
            formData.append('nombre', nombre);
            formData.append('precio', precio);
            formData.append('fechaInicio', fechaInicio);
            formData.append('fechaFin', fechaFin);
            formData.append('productos', auxProductos);
            try {
                const response = await axios({
                    url: Url + 'api/combosAlta.php',
                    method: 'POST',
                    data: formData,
                })
                console.log(response)
                let aux = modify + 1;
                setModify(aux);
                setViewOpen(false)
            } catch (e) {
                console.log(e)
            }
        }

    }

    const handleSelect = (element) => {
        setProducto(element.id)
    }

    return (
        <div className="w-full mx-auto p-5 rounded-2xl">
            <div className="transition-all w-full sm:p-3">
                <h2 className='text-3xl uppercase mb-3 text-center'>Nuevo Combo</h2>
                <div className='w-full flex'>
                    <div className="sm:items-start w-full sm:w-1/3 p-2">
                        <label htmlFor="codigo" className="block text-gray-600 uppercase font-bold">Código</label>
                        <input
                            className="py-2 px-3 w-full rounded bg-white border border-gray-300"
                            id="codigo"
                            placeholder="Código"
                            type="text"
                            value={codigo}
                            onChange={(e) => setCodigo(e.target.value)}
                        />
                    </div>
                    <div className="sm:items-start w-full sm:w-2/3 p-2">
                        <label htmlFor="nombre" className="block text-gray-600 uppercase font-bold">Nombre o Descripción</label>
                        <input
                            className="py-2 px-3 w-full rounded bg-white border border-gray-300"
                            id="nombre"
                            placeholder="Nombre o Descripción"
                            type="text"
                            value={nombre}
                            onChange={(e) => setNombre(e.target.value)}
                        />
                    </div>
                </div>
                <div className="sm:items-start w-full sm:w-1/3 p-2">
                    <label htmlFor="precio" className="block text-gray-600 uppercase font-bold">Precio de Venta</label>
                    <input
                        className="py-2 px-3 w-full rounded bg-white border border-gray-300"
                        id="precio"
                        placeholder="Precio de Venta"
                        type="text"
                        value={precio}
                        onChange={(e) => setPrecio(e.target.value)}
                    />
                </div>
                <div className='w-full flex'>
                    <div className='w-1/2 p-2'>
                        <label htmlFor="fechaInicio" className="w-full block text-gray-600 uppercase font-bold">Fecha de Inicio</label>
                        <input
                            className="py-2 px-3 w-full rounded border border-gray-300"
                            id="fechaInicio"
                            placeholder="Fecha de Inicio"
                            type="date"
                            value={fechaInicio}
                            onChange={(e) => setFechaInicio(e.target.value)}
                        />
                    </div>
                    <div className='w-1/2 p-2'>
                        <label htmlFor="fechaFin" className="w-full block text-gray-600 uppercase font-bold">Fecha de Fin</label>
                        <input
                            className="py-2 px-3 w-full rounded border border-gray-300"
                            id="fechaFin"
                            placeholder="Fecha de Fin"
                            type="date"
                            value={fechaFin}
                            onChange={(e) => setFechaFin(e.target.value)}
                        />
                    </div>
                </div>
                <div className='sm:items-start flex'>
                    <div className='w-2/3 p-2'>
                        <label htmlFor="cantidad" className="block mt-5 text-gray-600 uppercase font-bold">Seleccione Productos</label>
                        <Select
                            options={productos}
                            placeholder="Seleccione Producto para agregar a la promoción"
                            onChange={(value) => handleSelect(value)}
                            name='sProducto'
                        />
                        <button
                            type='button'
                            className={`mt-2 text-white p-3 rounded font-bold ${(producto !== 0 && cantidad !== 0) ? 'bg-blue-600' : 'bg-blue-200'}`}
                            onClick={() => addProductoCombo()}
                        >Agregar Producto seleccionado a la promoción</button>
                    </div>
                    <div className='w-1/3 p-2'>
                        <label htmlFor="cantidad" className="block mt-5 text-gray-600 uppercase font-bold">Cantidad</label>
                        <input
                            className="py-2 px-3 w-full rounded bg-white border border-gray-300"
                            id="cantidad"
                            placeholder="Cantidad"
                            type="text"
                            value={cantidad}
                            onChange={(e) => setCantidad(e.target.value)}
                        />
                    </div>
                </div>
                {productosCombo.length > 0 && (
                    <table className='table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto' >
                        <thead>
                            <tr className='uppercase bg-blue-400 text-white'>
                                <th className='border-gray-400 p-2'>Producto</th>
                                <th className='border-gray-400 p-2'>Cantidad</th>
                                <th className='border-gray-400 p-2'>Acciones</th>
                            </tr>
                        </thead>
                        <tbody >
                            {
                                productosCombo.map((item, index) => (
                                    <tr
                                        key={item.id}
                                        className={`hover:bg-slate-200 border-b border-gray-200 ${index % 2 === 0 ? 'bg-white' : 'bg-slate-100'}`}
                                    >
                                        <td className='border-gray-400 p-1 text-center'>{item.descripcion}</td>
                                        <td className='border-gray-400 p-1 text-center'>{item.cantidad}</td>
                                        <td className='p-1 flex justify-center'>
                                            <button
                                                className='bg-red-500 p-3 rounded-md text-white ml-3'
                                                onClick={() => delProductoCombo(item.id)}
                                            >
                                                <FaTrash />
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>

                )}
            </div>
            <div className="pb-1">
                <div className="w-full rounded-lg px-3 flex justify-center">
                    <button
                        type="button"
                        className={`w-1/4 sm:w-1/6 shadow-md p-3 text-gray-100 transition-all uppercase font-bold rounded-lg ${guardar ? 'bg-indigo-600 hover:bg-indigo-400' : ' bg-indigo-200'}`}
                        onClick={() => addCombo()}
                    >
                        Guardar Combo
                    </button>


                    <button
                        type="button"
                        className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                        onClick={() => setViewOpen(false)}
                    >
                        Salir
                    </button>
                </div>
            </div>
        </div>
    )
}

export default FormCombos